<template>
  <b-navbar toggleable="md" variant="faded" class="px-5 py-3" id="nav-1">
    <b-navbar-brand :to="{ name: 'home' }" class="p-0 m-0">
      <img src="@/assets/img/api-monitor-logo.png" width="200"/>
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse-1" class="ms-auto p-0"></b-navbar-toggle>
    <b-collapse id="nav-collapse-1" is-nav :class="[$route.name != 'login' && $route.name != 'password.reset' ? '' : 'd-none']">
      <b-navbar-nav class="ms-auto pt-3 pt-md-0">
        <b-nav-item :to="{ name: 'login' }" v-if="!this.$session.exists()">
          <b-button v-if="$route.name != 'login' && $route.name != 'password.reset'"
            class="ms-auto w-100 px-5" variant="primary" size="lg">Go to Login</b-button>
        </b-nav-item>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: "Navbar",
};
</script>

<template>
  <div class="container_forgot_password">
    <Navbar/>
    <div class="container_forgot_password__container_form">
      <b-container class="h-100">
        <b-row align-h="center" class="h-100">
          <b-col cols="12" md="7" lg="6" xl="4" align-self="center">
            <form @submit.prevent="sendForm">
              <b-card no-body>
                <b-container>
                  <b-row>
                    <b-col>
                      <h3 class="mb-4">Reset password</h3>
                      
                      <!-- Password -->
                      <b-form-group class="mb-4">
                        <b-form-input type="password" placeholder="New password" trim
                          :class="{'is-invalid': $v.form.password.$error || (!checkPassword.success && form.password != '' && form.password != null)}"
                          v-model.trim="$v.form.password.$model"></b-form-input>
                        <span role="alert" class="invalid-feedback" v-if="$v.form.password.$error">
                          <span v-if="!$v.form.password.required">The new password is required</span>
                          <span v-if="!$v.form.password.maxLength">
                            The new password must be less than {{$v.form.password.$params.maxLength.max}} characters
                          </span>
                        </span>
                        <span class="validation_password mt-1" v-if="form.password != '' && form.password != null">
                          <span class="validation_password--text" :class="[checkPassword.level]">
                            {{checkPassword.title}}
                          </span>
                          <span class="validation_password--description">
                            {{checkPassword.description}}
                          </span>
                        </span>
                      </b-form-group>

                      <!-- Confirm Password -->
                      <b-form-group class="mb-4">
                        <b-form-input type="password" placeholder="Confirm Password" trim
                          :class="{'is-invalid': $v.form.password_confirmation.$error}" v-model.trim="$v.form.password_confirmation.$model"></b-form-input>
                        <span role="alert" class="invalid-feedback" v-if="$v.form.password_confirmation.$error">
                          <span v-if="!$v.form.password_confirmation.required">The confirm password is required.</span>
                          <span v-if="!$v.form.password_confirmation.sameAsPassword && $v.form.password_confirmation.required">The confirm password confirmation does not match.</span>
                        </span>
                      </b-form-group>

                      <!-- Button -->
                      <b-button type="submit" variant="primary" class="mb-4 w-100">Save</b-button>
                      <div class="w-100 text-center">
                        <router-link :to="{ name: 'login' }" class="text-decoration-none forgot fw-bold link-primary">
                          Go to Login
                        </router-link>
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
              </b-card>
            </form>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <Footer/>
    <b-overlay :show="load" rounded="sm" no-wrap></b-overlay>
  </div>
</template>

<script>
import {required, sameAs, minLength, maxLength} from 'vuelidate/lib/validators';
import Navbar from '@/components/Auth/Navbar.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'ResetPassword',
  components: {
    Navbar,
    Footer
  },
  data () {
    return {
      form: {
        password: String(),
        password_confirmation: String(),
        token: this.$route.params.token,
      },
      load: false,
    }
  },
  beforeCreate() {
    this.$http.get(`password/reset/${this.$route.params.token}`).then((response) => { }, (error) => {
      if (error.status === 500 || error.status === 419) {
        this.$router.push({ name: 'page.419' });
      }
    });
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        maxLength: maxLength(30),
        isValid(value){
          return this.checkLevelPassword(value).success;
        }
      },
      password_confirmation: { required, sameAsPassword: sameAs('password') },
    },
  },
  methods: {
    sendForm() {
      this.$v.form.$reset();
      this.$v.form.$touch();
      if (!this.$v.form.$invalid) {
        this.load = true;
        
        this.$http.post(`password/reset`, this.form).then((response) => {
          if (response.status === 200) {
            this.$notify({ group: 'notifications_home', text: response.body.message, type: 'confirmation', duration: 5000 });
            this.$router.push({ name: 'login' });
          }
          this.load = false;
        }, (error) => {
          if (error.status === 400){
            this.$notify({ group: 'notifications_home', text: error.body.message, type: 'warning' });
          } else if (error.status === 419) {
            this.$notify({ group: 'notifications_home', text: error.body.message, type: 'warning' });
          } else if (error.status === 500) {
            this.$notify({ group: 'notifications_home', text: error.body.message, type: 'warning' });
          }
          this.load = false;
        })
      }
    },
  },
  computed: {
    checkPassword(){
      return this.checkLevelPassword(this.form.password);
    }
  },
}
</script>
